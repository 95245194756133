import { useState, useContext, Context, useEffect } from 'react'
import classnames from 'classnames'

import { t } from '../../utils/i18n/index'
import styles from './_listSort.module.scss'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export enum FilterValues {
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  CURRENTLY_TRENDING = 'CURRENTLY_TRENDING',
  GREATEST_HITS = 'GREATEST_HITS',
  NEWEST_RECENTLY_SCHEDULED = 'NEWEST_RECENTLY_SCHEDULED',
  OLDEST_RECENTLY_SCHEDULED = 'OLDEST_RECENTLY_SCHEDULED',
  DRAFT = 'DRAFT',
  SHARED = 'SHARED',
  TEAM = 'TEAM',
  PERSONAL = 'PERSONAL',
  ALL = 'ALL',
  ARTIST = 'ARTIST',
  SONG_TITLE = 'SONG_TITLE'
}

const defaultFilters = {
  alphabetical: [
    t('alphabetical'),
    [t('a to z'), FilterValues.A_TO_Z],
    [t('z to a'), FilterValues.Z_TO_A]
  ],
  recentlyAdded: [
    t('recently added'),
    [t('newest'), FilterValues.NEWEST],
    [t('oldest'), FilterValues.OLDEST]
  ],
  popularity: [
    t('popularity'),
    [t('currently trending'), FilterValues.CURRENTLY_TRENDING],
    [t('greatest hits'), FilterValues.GREATEST_HITS]
  ],
  recentlyScheduled: [
    t('recently scheduled'),
    [t('newest'), FilterValues.NEWEST_RECENTLY_SCHEDULED],
    [t('oldest'), FilterValues.OLDEST_RECENTLY_SCHEDULED]
  ],
  setlists: [
    t('view setlists'),
    [t('draft'), FilterValues.DRAFT],
    [t('shared'), FilterValues.SHARED],
    [t('personal'), FilterValues.PERSONAL],
    [t('team'), FilterValues.TEAM],
    [t('all setists'), FilterValues.ALL]
  ],
  setlistMusicant: [
    t('view setlists'),
    [t('personal'), FilterValues.PERSONAL],
    [t('team'), FilterValues.TEAM],
    [t('all setists'), FilterValues.ALL]
  ],
  storeDownloads: [
    [],
    [t('newest'), FilterValues.NEWEST],
    [t('artist'), FilterValues.ARTIST],
    [t('song title'), FilterValues.SONG_TITLE]
  ]
}

type ListSortProps = {
  children?: never
  SearchContext: Context<any>
  filtersNames?: string[]
  contextFieldName?: string // e.g. orderBy, filter
  isStore?: boolean
  onUpdate?: (filtersState) => void
}

const ListSort: React.FC<ListSortProps> = ({
  SearchContext,
  filtersNames = ['alphabetical', 'recentlyAdded', 'popularity'],
  contextFieldName = 'orderBy',
  isStore = false,
  onUpdate = null
}) => {
  const { filtersState, setFiltersState } = useContext(SearchContext)
  const filters = filtersNames.map((filter) => defaultFilters[filter])

  const nameValPair = filters
    .flatMap(([_, ...pairs]) => pairs)
    .find(([_, val]) => val === filtersState[contextFieldName])
  const [filter, setFilter] = useState(nameValPair)
  const [filterOpen, setFilterOpen] = useState(false)

  const onSortPick = (name, val) => {
    setFilter([name, val])
    setFilterOpen(false)
    if (onUpdate) {
      onUpdate({
        ...filtersState,
        [contextFieldName]: val
      })
    } else {
      setFiltersState((prevState) => ({
        ...prevState,
        [contextFieldName]: val
      }))
    }
  }

  useEffect(() => {
    if (nameValPair) setFilter(nameValPair)
  }, [filtersState[contextFieldName]])

  return (
    <div
      className={classnames('button', styles.listSort, styles.listSortContent, {
        [styles.storeListSort]: isStore,
        [styles.listSortContentActive]: filterOpen
      })}
      onClick={() => {
        setFilterOpen(!filterOpen)
      }}
    >
      {!isStore && <span className={styles.filterActiveType}>{t('Sort')}</span>}
      <span className={styles.filterActiveValue}>{filter[0]}</span>
      {isStore && !filterOpen && (
        <span>
          <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
        </span>
      )}
      <div className={styles.filterValuesList}>
        {filters.flatMap(([category, ...pairs]) => {
          const categoryEl = (
            <div key={category as string} className={styles.categoryItem}>
              <span className={styles.listSortCategory}>{category}</span>
            </div>
          )

          const selections = pairs.map(([name, val]) => (
            <button
              key={val}
              className={classnames(styles.filterValuesListItem, {
                [styles.selected]: filter[1] === val
              })}
              onClick={() => {
                onSortPick(name, val)
              }}
            >
              {name}
            </button>
          ))
          return (
            <div key={category}>
              {categoryEl}
              {selections}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ListSort
